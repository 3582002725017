const raw_data = [
  {
    "image": "utilities",
    "text": "Utilities",
    "mau": "191,217,000",
    "mau_change": "N/A",
    "website": null,
    "details": [
      "Chinese Name: 生活激费",
      "Mini Program Type: Lifestyle",
      "Publisher: Shenzhen Tencent Computer System Co.,Ltd."
    ]
  },
  {
    "image": "wechat_city_services",
    "text": "WeChat City Services",
    "mau": "152,686,400",
    "mau_change": "N/A",
    "website": null,
    "details": [
      "Chinese Name: 微信城市服务",
      "Mini Program Type: Lifestyle",
      "Publisher: Shenzhen Tencent Computer System Co.,Ltd."
    ]
  },
  {
    "image": "tongcheng_travel",
    "text": "Tongcheng Travel",
    "mau": "142,798,200",
    "mau_change": "N/A",
    "website": "https://www.tongchengir.com/",
    "details": [
      "Chinese Name: 同程旅行订酒店机票火车汽车门票",
      "Mini Program Type: Travel",
      "Publisher: Suzhou Tongcheng Tourism Network Technology Co.,Ltd."
    ]
  },
  {
    "image": "meituan",
    "text": "Meituan",
    "mau": "141,883,600",
    "mau_change": "N/A",
    "website": "https://www.meituan.com/en-US/about-us",
    "details": [
      "Chinese Name: 美团团购丨优选外卖单车美食酒店",
      "Mini Program Type: Lifestyle",
      "Publisher: Beijing Sankuai Online Technology Co., Ltd"
    ]
  },
  {
    "image": "sf_express",
    "text": "SF Express",
    "mau": "124,153,000",
    "mau_change": "N/A",
    "website": "https://www.sf-express.com/chn/en",
    "details": [
      "Chinese Name: 顺丰速运+",
      "Mini Program Type: Delivery",
      "Publisher: SF Express Co., Ltd"
    ]
  },
  {
    "image": "tencent_documents",
    "text": "Tencent Documents",
    "mau": "99,334,800",
    "mau_change": "N/A",
    "website": "https://docs.qq.com/desktop/?nlc=1",
    "details": [
      "Chinese Name: 腾讯文档",
      "Mini Program Type: Productivity",
      "Publisher: Shenzhen Tencent Computer System Co.,Ltd."
    ]
  },
  {
    "image": "shouqianba",
    "text": "Shouqianba",
    "mau": "98,530,600",
    "mau_change": "N/A",
    "website": "https://www.shouqianba.com/benefit.html",
    "details": [
      "Chinese Name: 收钱吧福利",
      "Mini Program Type: Productivity",
      "Publisher: Shanghai Wosai Internet Technology Co., Ltd"
    ]
  },
  {
    "image": "china_mobile_cloud",
    "text": "China Mobile Cloud",
    "mau": "92,244,200",
    "mau_change": "N/A",
    "website": null,
    "details": [
      "Chinese Name: 中国移动云盘",
      "Mini Program Type: Productivity",
      "Publisher: China Mobile Communications Group Co.,Ltd"
    ]
  },
  {
    "image": "didi_chuxing",
    "text": "DiDi Chuxing",
    "mau": "91,157,000",
    "mau_change": "N/A",
    "website": "https://web.didiglobal.com/",
    "details": [
      "Chinese Name: 滴滴出行",
      "Mini Program Type: Ride-hailing",
      "Publisher: Beijing Xiaoju Technology Co., Ltd"
    ]
  },
  {
    "image": "tencent_mobile_top-up",
    "text": "Tencent Mobile Top-up",
    "mau": "90,026,700",
    "mau_change": "N/A",
    "website": "https://chong.qq.com/",
    "details": [
      "Chinese Name: 腾讯手机充值",
      "Mini Program Type: Lifestyle",
      "Publisher: Shenzhen Tencent Computer System Co.,Ltd."
    ]
  },
  {
    "image": "little_ledger",
    "text": "Little Ledger",
    "mau": "86,010,700",
    "mau_change": "N/A",
    "website": null,
    "details": [
      "Chinese Name: 收款小账本",
      "Mini Program Type: Finance",
      "Publisher: Shenzhen Tencent Computer System Co.,Ltd."
    ]
  },
  {
    "image": "meituan_delivery",
    "text": "Meituan Delivery",
    "mau": "82,405,200",
    "mau_change": "N/A",
    "website": null,
    "details": [
      "Chinese Name: 美团外卖",
      "Mini Program Type: Lifestyle",
      "Publisher: Beijing Sankuai Online Technology Co., Ltd"
    ]
  },
  {
    "image": "jingdong_(jd)",
    "text": "JingDong (JD)",
    "mau": "81,999,100",
    "mau_change": "N/A",
    "website": "https://global.jd.com/",
    "details": [
      "Chinese Name: 京东",
      "Mini Program Type: Mobile Shopping",
      "Publisher: Beijing Jingdong Century Trading Co., Ltd"
    ]
  },
  {
    "image": "chengche_ma",
    "text": "Chengche Ma",
    "mau": "78,140,100",
    "mau_change": "N/A",
    "website": null,
    "details": [
      "Chinese Name: 乘车码",
      "Mini Program Type: Travel",
      "Publisher: Shenzhen Tencent Computer System Co.,Ltd."
    ]
  },
  {
    "image": "tencent_health",
    "text": "Tencent Health",
    "mau": "73,215,600",
    "mau_change": "N/A",
    "website": "https://healthcare.tencent.com/",
    "details": [
      "Chinese Name: 腾讯健康",
      "Mini Program Type: Healthcare",
      "Publisher: Shenzhen Tencent Computer System Co.,Ltd."
    ]
  },
  {
    "image": "happy_poker",
    "text": "Happy Poker",
    "mau": "71,841,100",
    "mau_change": "N/A",
    "website": null,
    "details": [
      "Chinese Name: 欢乐斗地主",
      "Mini Program Type: Gaming",
      "Publisher: Shenzhen Tencent Computer System Co.,Ltd."
    ]
  },
  {
    "image": "wechat_ledger",
    "text": "WeChat Ledger",
    "mau": "70,289,200",
    "mau_change": "N/A",
    "website": null,
    "details": [
      "Chinese Name: 微信记账本",
      "Mini Program Type: Finance",
      "Publisher: Shenzhen Tencent Computer System Co.,Ltd."
    ]
  },
  {
    "image": "wps_docs",
    "text": "WPS Docs",
    "mau": "67,837,700",
    "mau_change": "N/A",
    "website": "https://www.kdocs.cn/",
    "details": [
      "Chinese Name: 金山文档",
      "Mini Program Type: Productivity",
      "Publisher: Zhuhai Kingsoft Office Software Co., Ltd."
    ]
  },
  {
    "image": "blessing_circle",
    "text": "Blessing Circle",
    "mau": "64,228,400",
    "mau_change": "N/A",
    "website": "http://m.kuai8.com/xcx/9527.html",
    "details": [
      "Chinese Name: 祝福圈子",
      "Mini Program Type: Video",
      "Publisher: Beijing Xiaoniangao Internet Technology Co., Ltd."
    ]
  },
  {
    "image": "piaojuan_vlog",
    "text": "Piaojuan Vlog",
    "mau": "62,726,200",
    "mau_change": "N/A",
    "website": "http://m.kuai8.com/xcx/10023.html",
    "details": [
      "Chinese Name: 票圈vlog一原创视频生活社区",
      "Mini Program Type: RPG (Role-Playing Game)/MOBA (Multiplayer Online Battle Arena)",
      "Publisher: Shenzhen Tencent Computer System Co.,Ltd."
    ]
  }
];

// Remove the mau_change property from the data
const processed_data = raw_data.map((it) => {
  delete it.mau_change;
  return it;
});

export const WECHAT_MNINIPROGRAMS = processed_data.map((it, index) => ({
  ...it,
  index: index + 1,
}));
