import React from "react";
import Meta from "components/shared/Meta";
import Nav from "components/shared/Nav";
import Footer from "components/shared/Footer";
import Layout from "components/shared/Layout";
import WechatMiniprograms from "../../components/market/WechatMiniprograms";
import CTA from "components/shared/CTA";
import MarketRelatedContent from "components/market/MarketRelatedContent";
import LargeTitle from "components/services/LargeTitle";
import icon from "images/svg_icons/applications.svg";
export default () => (
  <>
    <Meta url="/market/wechat-mp/" />
    <Nav />
    <LargeTitle
      img={icon}
      green="WeChat Mini Program"
      black="Index"
      text={
        // The AppInChina WeChat Mini Program index ranks the top 20 WeChat Mini Programs based on their monthly average users. For more information on how to develop a WeChat Mini Program, refer to our full guide https://appinchina.co/services/wechat-miniprograms/. To receive a quote for developing your own WeChat Mini Program, contact us https://appinchina.co/get-started/
        <p>
            The AppInChina WeChat Mini Program index ranks the top 20 WeChat Mini Programs based on their monthly average users. For more information on how to develop a WeChat Mini Program, refer to our full guide <a href="https://appinchina.co/services/wechat-miniprograms/">here</a>. To receive a quote for developing your own WeChat Mini Program, contact us <a href="https://appinchina.co/get-started/">here</a>.
            <br />

            <b>Last Updated: March 2025</b>
        </p>
      }
    />
    <br/><br/>
    <Layout>
      <MarketRelatedContent>
        <WechatMiniprograms />
      </MarketRelatedContent>
      <br />
      <br />
    </Layout>
    <CTA titleLevel="h3" />
    <Footer titleLevel="h4" />
  </>
);
