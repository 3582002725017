import React from "react";
import Table from "components/shared/Table";
import { WECHAT_MNINIPROGRAMS } from "src/utils/market/wechat-miniprograms";
import * as classes from "../rankings.module.less";

export default () => {
  let columns = [
    {
      hideResponsiveTitle: true,
      title: "Wechat Mini Program",
      dataIndex: "text",
      render: (text, element) => (
        <div className={classes.storeName}>
          <i>{element.index}</i>
          {element.website ? (
            <a href={element.website} rel="noopener noreferrer" target="_blank">
              <span>{text}</span>
            </a>
          ) : (
            <span>{text}</span>
          )}
        </div>
      ),
    },
    {
      title: "Monthly Active Users (MAU)",
      dataIndex: "mau",
      className: classes.right,
    }
  ];

  return (
    <Table
      responsive
      dataSource={WECHAT_MNINIPROGRAMS}
      columns={columns}
      rowKey="index"
      expandedRowRender={(record) => (
        <div>
          {record.details.map((item) => (
            <p className={classes.detailsP}>{item}</p>
          ))}
        </div>
      )}
    />
  );
};
